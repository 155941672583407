import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navr from "./components/navbr/Navr"
import Sidebar from "./components/sidebar/Sidebar";

function App(){
  return(
    <>
    <Sidebar/>
    </>
  );
}



export default App;